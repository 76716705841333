import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import { PrismicFooter, PrismicNav } from '@utils/typings/prismic-types';

interface Props extends PageProps {
  data: {
    prismicNav: PrismicNav;
    prismicFooter: PrismicFooter;
  };
}

export default function PrivacyPolicy({ data }: Props) {
  if (!data) return null;

  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  return (
    <>
      <Nav nav={nav} />

      <div className="content-narrow">
        <h1 className="main-heading">Privacy Policy</h1>
        <h2 className="section-heading">
          Collection and Use of Personal Information
        </h2>
        <h5 className="section-subheading">For everyone</h5>
        <p>We collect the following personal information from you:</p>
        <ul className="list">
          <li className="list-item p_small">IP Address.</li>
          <li className="list-item p_small">
            Contact information such as name, email address, mailing address,
            phone number
          </li>
          <li className="list-item p_small">
            Preferences information such as email subscription type preferences
            and communication preferences
          </li>
          <li className="list-item p_small">
            Information about your business such as company name, company size,
            industry
          </li>
          <li className="list-item p_small">
            Demographic information such as region, job title, interests and zip
            code
          </li>
          <li className="list-item p_small">
            Only when necessary to determine suitable products and services, may
            we collect financial information such as bank or brokerage account
            numbers, types of investments, income, revenue, assets, credits,
            deductions, expenses, and bank account information
          </li>
          <li className="list-item p_small">
            As is true of most websites, we also automatically gather
            information about your cookies, as detailed the section regarding
            Cookies and Other Tracking Technologies
          </li>
        </ul>
        <p>We use this information to:</p>
        <ul className="list">
          <li className="list-item p_small">
            Assess the needs of your business to determine suitable products and
            services
          </li>
          <li className="list-item p_small">
            Send you requested product or service information
          </li>
          <li className="list-item p_small">
            Send you marketing communications, such as newsletters and blog
            posts Improve our website and marketing efforts
          </li>
          <li className="list-item p_small">Conduct research and analysis</li>
          <li className="list-item p_small">
            Display content based upon your interests
          </li>
        </ul>
        <h5 className="section-subheading">For Pry Financials clients only</h5>
        <p>
          In addition to the information above, the following data is collected
          from clients to fulfill our contractual obligation to deliver
          accounting and tax products and services:
        </p>
        <ul className="list">
          <li className="list-item p_small">
            Billing information such as credit card number and billing address
          </li>
          <li className="list-item p_small">
            Financial information such as bank or brokerage account numbers,
            types of investments, shareholder information such as cap table,
            income, revenue, assets, credits, deductions, expenses, and bank
            account information
          </li>
          <li className="list-item p_small">
            Unique identifiers such as user name, account number, and password
          </li>
          <li className="list-item p_small">Date of Birth</li>
        </ul>
        <p>We use this information to:</p>
        <ul className="list">
          <li className="list-item p_small">Establish your identity</li>
          <li className="list-item p_small">
            Assess the needs of your business to determine if any suitable
            products or services are necessary
          </li>
          <li className="list-item p_small">
            Send you requested product or service information
          </li>
          <li className="list-item p_small">Send product updates</li>
          <li className="list-item p_small">
            Respond to customer service requests
          </li>
          <li className="list-item p_small">Administer your account</li>
          <li className="list-item p_small">
            Provide requested financial services
          </li>
          <li className="list-item p_small">
            Respond to your questions and concerns
          </li>
        </ul>
        <h2 className="section-heading">Choice/Opt-Out</h2>
        <p>
          You may choose to stop receiving our marketing or sales emails by
          following the unsubscribe instructions included in these emails or you
          can contact us at{' '}
          <a
            href="mailto:support@pry.co?subject=Choice%2FOpt-out"
            className="link"
          >
            support@pry.co
          </a>
          .
        </p>
        <h2 className="section-heading">
          Information Obtained from Third Parties
        </h2>
        <p>
          If you provide us personal information about others, or if others give
          us your information, we will only use that information for the
          specific reason for which it was provided to us. An example of this is
          when you refer a friend or colleague to Pry Financials.
          <br />
          <br />
          By providing Pry Financials with a friend or colleague’s personal
          information, you act as the authorized party to disclose that
          information to Pry Financials so that we may collect, use, and
          disclose such information for the purposes described in this Privacy
          Policy. This means that you must take reasonable steps to ensure the
          individual concerned is aware of and/or consents to topics in this
          Privacy Policy, including the collection of their personal
          information, the purposes for which that information is being
          collected, the intended recipients of that information, the
          individual&apos;s right to obtain access to that information, Pry
          Financials’s identity, and how to contact Pry Financials.
          <br />
          <br />
          Where requested to do so by Pry Financials, you must also assist Pry
          Financials with any requests by the individual to access or update the
          personal information you have collected from them.
        </p>
        <h2 className="section-heading">Information Sharing</h2>
        <p>
          We will share your personal information with third parties only in the
          ways that are described in this privacy statement. We do not sell your
          personal information to third parties.
          <br />
          <br />
          We may provide your personal information to companies that we partner
          with in joint marketing efforts. In this case, the webpage on which
          you are submitting your personal information will explicitly state
          that the data will be shared, and who it will be shared with.
          <br />
          <br />
          We may provide your personal information to companies that provide
          services to help us with our business activities. These companies are
          authorized to use your personal information only as necessary to
          provide these services to us.
          <br />
          <br />
          We may also disclose your personal information:
        </p>
        <ul className="list">
          <li className="list-item p_small">
            As required by law such as to comply with a subpoena, or similar
            legal process.
          </li>
          <li className="list-item p_small">
            When we believe in good faith that disclosure is necessary to
            protect our rights, protect your safety or the safety of others,
            investigate fraud, or respond to a government request.
          </li>
          <li className="list-item p_small">
            If Pry Financials is involved in a merger, acquisition, or sale of
            all or a portion of its assets, you will be notified via email
            and/or a prominent notice on our website of any change in ownership
            or uses of your personal information, as well as any choices you may
            have regarding your personal information.
          </li>
          <li className="list-item p_small">
            To any other third party with your prior consent to do so.
          </li>
        </ul>
        <h2 className="section-heading">
          Cookies and Other Tracking Technologies
        </h2>
        <p>
          We use cookies to keep track of your preferences and profile
          information. Cookies are also used to collect general usage and volume
          statistical information that does not include personal information. We
          use another company to place cookies on your computer to collect
          non-personally identifiable information to compile aggregated
          statistics for us about visitors to our site.
        </p>
        <h5 className="section-subheading">Web Beacons</h5>
        <p>
          Our webpages may contain electronic images known as Web beacons
          (sometimes called single-pixel gifs) that are used along with cookies
          to compile aggregated statistics to analyze how our site is used. They
          are also used in some of our emails to let us know which emails and
          links have been opened by recipients. This allows us to gauge the
          effectiveness of our customer communications and marketing campaigns.
          <br />
          <br />
          Our webpages may also contain Web beacons that are set by our third
          party partners. Web beacons are used along with cookies in order to
          enable our partners to compile aggregated statistics to analyze how
          our site is used.
          <br />
          <br />
          We use a third party to gather information about how you and others
          use our website. For example, we will know how many users access a
          specific page and which links they clicked on. We use this aggregated
          information to understand and optimize how our site is used in order
          to deliver a better experience for you.
        </p>
        <h2 className="section-heading">Links to Other Websites</h2>
        <p>
          Our Site includes links to other websites whose privacy practices may
          differ from those of Pry Financials. If you submit personal
          information to any of those sites, your information is governed by
          their privacy statements. We encourage you to carefully read the
          privacy statement of any website you visit.
        </p>
        <h2 className="section-heading">Security</h2>
        <p>
          The security of your personal information is important to us. When you
          enter sensitive information on our forms, we encrypt the transmission
          of that information using secure socket layer technology (SSL).
          <br />
          <br />
          We follow generally accepted industry standards to protect the
          personal information submitted to us, both during transmission and
          once we receive it. No method of transmission over the Internet, or
          method of electronic storage, is 100% secure, however. Therefore, we
          cannot guarantee its absolute security.
          <br />
          <br />
          If you have any questions about security on our website, you can
          contact us at{' '}
          <a href="mailto:support@pry.co?subject=Security" className="link">
            support@pry.co
          </a>
        </p>
        <h2 className="section-heading">
          Where Data is Processed &amp; Stored
        </h2>
        <p>
          To provide you with products and services, we may store, process, and
          transmit information in the United States and locations around the
          world. Information may also be stored locally on the devices you use
          to access our products and services.
        </p>
        <h2 className="section-heading">
          How Long We Retain Your Personal Information
        </h2>
        <p>
          In accordance with applicable laws, we keep your information for as
          long as needed to serve you and maintain your account as needed to
          operate our business, or to improve our offerings or develop new ones.
          <br />
          <br />
          We retain your information for as long as your account is active or as
          needed to provide you services. We may retain or use your information
          as necessary to comply with our policies, legal obligations, resolve
          disputes, and enforce our agreements.
        </p>
        <h2 className="section-heading">
          Data Privacy Provisions for EU Citizens
        </h2>
        <p>
          We process your information to provide the products and services you
          request and for our legitimate business interests, including
          marketing, as well as to fulfill contractual obligations with you to
          deliver accounting and tax preparation products and services.
          <br />
          <br />
          As a citizen of the European Union (EU), you have certain rights
          regarding the processing of your personal data:
        </p>
        <ul className="list">
          <li className="list-item p_small">
            You have the right to access and correct your information.
          </li>
          <li className="list-item p_small">
            You have the right to obtain erasure of your information, object to
            or restrict processing activities related to your information, or
            withdraw your consent to the processing of your information after
            you have provided it.
          </li>
          <li className="list-item p_small">
            You have the right to obtain a copy of your information in a
            structured, commonly used, machine readable format.
          </li>
          <li className="list-item p_small">
            You have the right to lodge a complaint with your local supervisory
            authority regarding our processing of your information.
          </li>
          <li className="list-item p_small">
            Also, if the information you provide contains special categories of
            personal data, we will only process it with your explicit consent,
            which can be withdrawn at any time.
          </li>
          <li className="list-item p_small">
            For assistance in exercising any of these rights, please contact us
            at{' '}
            <a
              href="mailto:support@pry.co?subject=Data%20Privacy%20Provisions%20for%20EU%20Citizens"
              className="link"
            >
              support@pry.co
            </a>
            .
          </li>
        </ul>
        <h2 className="section-heading">
          Correcting and Updating Your Personal Information
        </h2>
        <p>
          To review and update your personal information to ensure it is
          accurate, contact us at{' '}
          <a
            href="mailto:support@pry.co?subject=Correcting%20My%20Personal%20Information"
            className="link"
          >
            support@pry.co
          </a>
          .
        </p>
        <h2 className="section-heading">
          Notification of Privacy Statement Changes
        </h2>
        <p>
          We may update this privacy statement to reflect changes to our
          information practices. If we make any material changes we will notify
          you by email (sent to the e-mail address specified in your account) or
          by means of a notice on this Site prior to the change becoming
          effective. We encourage you to periodically review this page for the
          latest information on our privacy practices.
        </p>
        <h2 className="section-heading">Contact</h2>
        <p>
          You may contact us at{' '}
          <a href="mailto:support@pry.co?subject=Hi" className="link">
            support@pry.co
          </a>
          .
        </p>
      </div>

      <Footer footer={footer} />
    </>
  );
}

export const query = graphql`
  query privacyPolicyQuery($lang: String) {
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`;
